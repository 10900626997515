import { Avatar, Menu } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import { DefaultImageUrl, MessageActions } from "../../Constant/Constant";
import MessageInfo from "./MessageInfo";
import VideoFileIcon from "../../Assets/Images/VideoFileIcon.png";
import { MdFileDownload } from "react-icons/md";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { MessagesActionDataTypes } from "../../Types/Types";
import StarIcon from "@mui/icons-material/Star";
import { emit, socketListen } from "../../Socket";
import { IoIosShareAlt } from "react-icons/io";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Toast from "../../coreComponent/Toast";
import { useTranslation } from "react-i18next";
import FilePreview from "../RightTab/database/FilePreview";
import messageSenderInfo from "../../utils/messageSenderInfo";

const VideoFilePreview = ({
  showDate,
  showDateforoutgoing,
  item,
  profileShown,
  setMessages,
  messages,
  setChangeFooter,
  setShowCheckbox,
  setData,
  setSelectedHeading,
  setSelectedHeadingId,
  setShowHeadingModal,
  showCheckbox,
  setReplyFooterShow,
  setReplyMessage,
  setMessageInfoItem,
  setMessageInfoDrawerShow,
}: any) => {
  const [showMessageActions, setShowMessageActions] = useState(false);
  const [activeFav, setActiveFav] = useState<any>({});
  const { topicsData } = useSelector((state: any) => state.user);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [showArrow, setshowArrow] = useState({ status: false, id: null });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const MessageAction = MessageActions(t);
  const { userData, userContacts } = useSelector((state: any) => state.user);
  const { formattedParticipantsOfActiveRoom, activeRoomData, pinnedChats,activeRoomChats } =
    useSelector((state: any) => state.chat);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [filePreview, setFilePreview] = useState({file: null, show: false})
  
  const deleted =
    item.deleted.length > 0
      ? item?.deleted?.filter(
        (element: any) => element.user_id === userData?._id
      )?.length > 0
        ? false
        : true
      : true;
      const userProfileImg = activeRoomData?.participants?.length > 0 && activeRoomData?.participants?.find((item: any) => item?.user_id === userData?._id);
      const isRoomLeft = userProfileImg?.left_at !== 0;
  const senderInfo = formattedParticipantsOfActiveRoom[item.sender];
  let messageTime = moment(item?.created_at).format("HH:mm");

  const messageSender = messageSenderInfo(item.sender, activeRoomData?.participants)

  const isEveryoneCanPinMessage =
    activeRoomData?.type === "group" &&
    activeRoomData?.access?.some(
      (item: any) => item?.type === "pinMessage" && item?.permit === "common"
    );

  const canYouPinMessage =
    (!isEveryoneCanPinMessage &&
      formattedParticipantsOfActiveRoom[userData?._id]?.user_type ===
      "admin") ||
    isEveryoneCanPinMessage;
  let userDetails = userContacts?.find(
    (item: any) => item?.userId?._id === senderInfo?.user_id
  );

  const isUserBlocked =
  activeRoomData?.participants?.find((el: any) => el?.left_at !== 0&&el?.user_id === userData?._id);

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setShowMessageActions(false);
    } else if (event.key === "Escape") {
      setShowMessageActions(false);
    }
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    //setshowArrow({ status: false, id: null }); 
    setAnchorEl(null);

  };

  useEffect(() => {
    document.addEventListener('click', handleClose);

    return () => {
      document.removeEventListener('click', handleClose);
    };
  }, []);

  const handleMessageAction = (action: string,item:any) => {
    setShowMessageActions(false);
    if (action === "forward" || action === "delete") {
      handleMessageSelect(true, item)
      setChangeFooter(true);
      setShowCheckbox(true);
    } else {
      setChangeFooter(false);
      setShowCheckbox(false);
    }

    if (action === "heading") {
      setData(topicsData);
      setSelectedHeading(item?.message);
      setSelectedHeadingId(item?._id);
      setShowHeadingModal(true);
    }
    if (action === "favourite") {
      emit("addChatsToFavourite", {
        roomId: activeRoomData?._id,
        cid: [item?._id],
      });
    } else if (action === "unFavourite") {
      emit("removeChatsFromFavourite", {
        roomId: activeRoomData?._id,
        cid: [item?._id],
      });
    }

    if (action === "reply") {
      setTimeout(() => {
        document.getElementById('message-input').focus()
    }, 0)
      setReplyMessage(item);
      setReplyFooterShow(true);
    }

    if (action === "info" && item?.sender === userData?._id) {
      setMessageInfoItem(item);
      setMessageInfoDrawerShow(true);
    }
    if (action === "pin") {
      if (canYouPinMessage) {
        if (pinnedChats?.length < 3) {
          emit("pinChat", { roomId: activeRoomData?._id, cid: [item?._id] });

          socketListen("message", (data: any) => {
            if (data.type === "pinChat") {
              setToastShow(true);
              setToastMessage(t("chat_pinned"));
            }
          });
        } else {
          setToastMessage(t("chat_pin_validation"));
          setToastShow(true);
        }
      } else {
        setToastMessage(t("chat_pin_admin"));
        setToastShow(true);
      }
    }
  };

  const handleMessageSelect = (e:any, item: any) => {
    let temp = [...messages.msgId];
    let tempMsgId = [...messages.senderId];
    let tempTime = [...messages.time];

    if (e) {
      temp.push(item?._id);
      tempMsgId.push(item?.sender);
      tempTime.push(item?.created_at);
    } else {
      temp = temp.filter((elem: any) => elem !== item?._id);
      tempMsgId = tempMsgId.filter((elem: any) => elem !== item?.sender);
      tempTime = tempTime.filter((elem: any) => elem !== item?.created_at);
    }
    setMessages({ msgId: temp, senderId: tempMsgId, time: tempTime });
  };

  const handleShowPreview = () => {
    setFilePreview({ file: { url: item?.fileURL, type: "VIDEO" }, show: true })
  }

  return (
    <React.Fragment>
      {item?.sender === userData?._id ? (
    <div className=" items-center" onMouseEnter={() => { setshowArrow({ status: true, id: item._id }); setAnchorEl(null); }}
    onMouseLeave={() => { setshowArrow({ status: false, id: null }); setAnchorEl(null); }}>
      <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />
      {showCheckbox && deleted && (
        <div className="h-[20px] w-[20px]">
          <input
            type="checkbox"
            name="message"
            className="mr-2 cursor-pointer h-[20px] w-[20px]"
            checked={messages?.msgId.includes(item._id)}
            onChange={(e: any) => handleMessageSelect(e.target.checked, item)}
          />
        </div>
      )}

      <div className="w-max ml-auto max-w-[400px] " >
        <div className="flex items-start">
        <div className="w-8 h-6" style={{ color: "#33CCFF", fontSize: '1.3rem' }}>
          {showArrow.status && showArrow.id === item?._id && deleted &&
            <div className="" onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setActiveFav(item);
            }} ref={anchorRef}>
              <MoreVertIcon className="-mr-2 cursor-pointer" sx={{ color: "#33CCFF", fontSize: '1.8rem' }} id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined} />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => { setAnchorEl(null); }}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {MessageAction.filter(
                  (elem: MessagesActionDataTypes) =>
                    activeRoomData?.type === "individual"
                      ? elem?.action !== "pin"
                      : elem
                ).map(
                  (el: MessagesActionDataTypes, index: number) => {
                    const pinchat = pinnedChats.filter((els: any) => els._id === item?._id);
                    return pinchat.length > 0 && el?.action === "pin" ? (
                      ""
                    ) : activeFav?.favourite_by?.length > 0 &&
                      el?.action === "favourite" ? (
                      ""
                    ) : activeFav?.favourite_by?.length === 0 &&
                      el?.action === "unFavourite" ? (
                      ""
                    ) : activeFav?.type === "VIDEO" &&
                      el?.action === "copy" ? (
                      ""
                    ) : (activeRoomData?.type == "broadcast" && el?.action == "heading")
                    ? ""
                    : (
                      isRoomLeft&&(el?.action === "forward"||el?.action==="reply"||el?.action==="delete") ?"": <MenuItem
                        onClick={() => { handleMessageAction(el.action,item); setshowArrow({ status: false, id: null }); setAnchorEl(null); }}
                        key={index}
                        sx={{ fontSize: "15px" }}
                      >
                        {el.ui}
                      </MenuItem>
                    );
                  }
                )}
              </Menu>

            </div>}
            </div>
          <div
            className={`flex items-end justify-end ${showDateforoutgoing ? "mb-1" : ""
              }`}
          >
            <div>


              <div
                onContextMenu={(e) => {
                  if (!item?.message?.includes("You deleted this message")) {
                    e.preventDefault();
                    setAnchorEl(e.currentTarget);
                    setShowMessageActions(true);
                    setActiveFav(item);
                  }
                }}
                className=" items-center bg-[#E0FAFF] p-4 rounded-t-xl rounded-bl-xl"
              >
                <div
                  className={`flex w-full ${item.isForwarded ? "justify-between" : "justify-end"
                    }`}
                >
                  <div className="textGray text-[13px] w-full flex justify-between pr-2">
                    <div className="flex">
                      {item?.isForwarded && (
                        <div className="flex pl-2 pt-1">
                          <IoIosShareAlt className="mt-1 me-1" />
                          <div className="text-sm textGray text-right">
                            {t("forwarded")}
                          </div>
                        </div>
                      )}
                    </div>
                    {showDateforoutgoing && messageTime}
                  </div>
                </div>
                <div className="flex items-center">
                  <div>
                    {deleted && (
                      <button>
                        <MdFileDownload
                          className="text-gray-500 me-1"
                          size={22}
                        />
                      </button>
                    )}
                  </div>
                  <div
                    ref={anchorRef}
                    onClick={() => {
                      setShowMessageActions(true);
                      setActiveFav(item);
                    }}
                  >
                    {deleted && (
                      <video
                        // controls
                        onClick={handleShowPreview}
                        src={DefaultImageUrl + item.fileURL}
                        className="rounded-xl max-h-[20rem] cursor-pointer"
                      ></video>
                    )}
                  </div>
                </div>
                {item?.favourite_by?.length > 0 && (
                  <div>
                    <StarIcon
                      sx={{ fontSize: 14 }}
                      className="text-black mt-1 me-1"
                    />
                  </div>
                )}
                {item?.message !== "" && (
                  <div className=" text-[16px] ms-6 mt-2 text-[#515151]">
                    {item.message === "You deleted this message" ? (
                      <i className="text-[14px] "> {item?.message.replace("You deleted this message",t("you-message-deleted"))}</i>
                    ) : (
                      item.message.replace("You deleted this message",t("you-message-deleted"))
                    )}
                  </div>)}
              </div>

            </div>

            {item.isSent && item.read_by.length > 0 &&item.receipts? (
              <></>
            ) : (
              <div className="flex items-end ">
                {item.isSent && item.delivered_to.length > 0 ? (
                  <DoneAllIcon className="text-gray-400" sx={{ width: 18, height: 18 }} />
                ) : (
                  <DoneIcon className="text-gray-400" sx={{ width: 18, height: 18 }} />
                )}
              </div>
            )}
          </div>
        </div>
        {item.receipts&&activeRoomChats[activeRoomChats.length-1]?._id===item._id&&item.isSent && item.read_by.length > 0 && (
          <div
            className={` ml-auto mt-2  ${activeRoomData?.type === "group" ? "w-12" : "w-fit ml-[5px]"
              } flex gap-1 items-end self-end justify-end`}
          >
            {<MessageInfo item={item} />}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="flex w-full" onMouseEnter={() => { setshowArrow({ status: true, id: item._id }); setAnchorEl(null); }}
    onMouseLeave={() => { setshowArrow({ status: false, id: null }); setAnchorEl(null); }}>
      <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />
      {showCheckbox && deleted && (
        <div className=" h-[20px] w-[20px] mr-4">
          <input
            type="checkbox"
            name="message"
            className="mr-2 cursor-pointer h-[20px] w-[20px]"
            checked={messages?.msgId.includes(item._id)}
            onChange={(e: any) => handleMessageSelect(e.target.checked, item)}
          />
        </div>
      )}

      <div>
        <div className="w-max flex pr-4 mr-auto  max-w-[400px]" >
          <div className="min-w-[35px]">
            {/* {showDate &&
              (senderInfo?.profile_img !== "" &&
                !isUserBlocked ? (
                <img
                  src={DefaultImageUrl + senderInfo?.profile_img}
                  alt="asas"
                  className="h-[35px] w-[35px] rounded-full"
                />
              ) : (
                <div className="">
                  <Avatar sx={{ width: "35px", height: "35px" }} />
                </div>
              ))} */}
              {showDate &&
              (messageSender?.profile !== "" &&
                !isUserBlocked ? (
                <img
                  src={DefaultImageUrl + messageSender?.profile}
                  alt="asas"
                  className="h-[35px] w-[35px] rounded-full"
                />
              ) : (
                <div className="">
                  <Avatar sx={{ width: "35px", height: "35px" }} />
                </div>
              ))}
          </div>
          <div className="flex items-start">

            <div
              onContextMenu={(e) => {
                if (!item?.message?.includes("This message was deleted")) {
                  e.preventDefault();
                  setAnchorEl(e.currentTarget);
                  setShowMessageActions(true);
                  setActiveFav(item);
                }
              }}
              className=" mx-2 bg-[#F4F4F4] rounded-tr-xl rounded-b-xl p-4 py-4 pt-2"
            >
              <div className={`flex relative gap-3 justify-between`}>
                <div className=" mb-2 text-sm flex">
                  {showDate && messageSender?.name}
                  {/* {showDate &&
                    (userDetails &&
                      (userDetails?.firstName !== "" ||
                        userDetails?.lastName !== "")
                      ? userDetails?.firstName + " " + userDetails?.lastName
                      : senderInfo?.phone)} */}
                  {item?.isForwarded && (
                    <div className="flex">
                      <IoIosShareAlt className="mt-1 mx-1" />
                      <div className="text-sm textGray text-right ">
                        {t("forwarded")}
                      </div>
                    </div>
                  )}
                </div>
                <div className="textGray text-xs pr-2 pt-1">
                  {showDate && messageTime}
                </div>
              </div>
              <div className="flex relative items-center gap-2">
                <div
                  ref={anchorRef}
                  onClick={() => {
                    setShowMessageActions(true);
                    setActiveFav(item);
                  }}
                >
                  {deleted && (
                    <video
                      // controls
                      onClick={handleShowPreview}
                      src={DefaultImageUrl + item.fileURL}
                      className="rounded-xl max-h-[20rem] cursor-pointer"
                    ></video>
                  )}
                </div>
                <div>
                  {deleted && (
                    <button>
                      <MdFileDownload className="text-gray-500" size={22} />
                    </button>
                  )}
                </div>
              </div>
              {item?.favourite_by?.length > 0 && (
                <div className="flex justify-end">
                  <StarIcon
                    sx={{ fontSize: 14 }}
                    className="text-black mt-1 me-1"
                  />
                </div>
              )}

              {item?.message !== "" && (
                <div
                  className="text-[16px] "
                >
                  {item?.message === 'This message was deleted' ? <i className="text-[14px] text-[#515151]">{item.message.replace("This message was deleted",t("this-message-deleted"))}</i> :
                    <div className='mt-1 text-[#515151]'>{item?.message.replace("This message was deleted",t("this-message-deleted"))}</div>}
                </div>
              )}
            </div>
            <div className="w-8 h-6" style={{ color: "#33CCFF", fontSize: '1.3rem' }}>
            {showArrow.status && showArrow.id === item?._id && deleted &&
              <div className="" onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setActiveFav(item);
              }} ref={anchorRef}>
                <MoreVertIcon className="-ml-2 cursor-pointer" sx={{ color: "#33CCFF", fontSize: '1.8rem' }} id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined} />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => { setAnchorEl(null); }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {MessageAction.filter((elem: MessagesActionDataTypes) =>
                    activeRoomData?.type === "individual"
                      ? elem?.action !== "pin" && elem.action !== "info"
                      : elem.action !== "info"
                  ).map((el: MessagesActionDataTypes, index: number) => {
                    const pinchat = pinnedChats.filter((els: any) => els._id === item?._id);
                    return pinchat.length > 0 && el?.action === "pin" ? (
                      ""
                    ) : activeFav?.favourite_by?.length > 0 &&
                      el?.action === "favourite" ? (
                      ""
                    ) : activeFav?.favourite_by?.length === 0 &&
                      el?.action === "unFavourite" ? (
                      ""
                    ) : activeFav?.type === "VIDEO" &&
                      el?.action === "copy" ? (
                      ""
                    ) : (activeRoomData?.type == "broadcast" && el?.action == "heading")
                    ? ""
                    : (
                      isRoomLeft&&(el?.action === "forward"||el?.action==="reply"||el?.action==="delete") ?"":<MenuItem
                        onClick={() => {
                          handleMessageAction(el.action,item);
                          setshowArrow({ status: false, id: null });
                          setAnchorEl(null);
                        }}
                        key={index}
                        sx={{ fontSize: "14px" }}
                      >
                        {el.ui}
                      </MenuItem>
                    );
                  })}
                </Menu>

              </div>}
              </div>
          </div>
        </div>
      </div>
    </div>
  )};

    {filePreview.show && (
      <FilePreview
        filePreview={filePreview}
        setFilePreview={setFilePreview} 
      />
    )}
    </React.Fragment>
  )
};

export default VideoFilePreview;
