import { io, Socket } from "socket.io-client";
import SendChatAuio from "../Assets/sound/sendchat.mp3";
import { DefaultImageUrl, SocketUrl } from "../Constant/Constant";
import {
  GET_CHATS_BY_ROOM_ID,
  GET_ON_GOING_CALLS,
  getMyNotifications,
  LOGOUT,
  ME,
} from "../Graphql/Queries";
import { authCllient, refreshTokenCllient } from "../Graphql/authClient";
import {
  appendOldChats,
  appendPreviousChats,
  getCallHistory,
  replaceMessageWithLocalId,
  resetActiveRoomChats,
  resetChat,
  setActiveCallData,
  setActiveRoomChats,
  setActiveRoomData,
  setActiveRoomsChats,
  setFavouriteChats,
  setGroupsInCommon,
  setIsRoomsLoading,
  setJoinedFromOtherDevice,
  setLeftCallData,
  setMemberAddedInCall,
  setNewMessageCount,
  setOnGoingCallData,
  setOnlineRooms,
  setParticipantsInCall,
  setPinnedChats,
  setRejectCallData,
  setRooms,
  setRoomsSession,
  setSearchChats,
  setUserMediaByRoomId,
  setUserOnlineStatus,
  updateActiveRoomChats,
  updateCallStatus,
  updateCreateCall,
} from "../Redux/ChatSlice";
import {
  resetOrg,
  setAssignments,
  setCurrentOrganization,
  setCurrentReport,
  setNotificationArray,
  setNotificationCount,
  setOrganizationInvite,
  setOrganizations,
  setShowTaskPage,
  updateAssignments,
  updateInvites,
} from "../Redux/OrganizationSlice";
import {
  resetUser,
  setComonUsers,
  setIsContactsLoading,
  setScrollBottom,
  setUserData,
} from "../Redux/UserSlice";
import { store } from "../Redux/store";
import { ChatAction } from "../utils/chat";
import { getSession } from "../utils/session";
import { v4 as uuidv4 } from "uuid";
import { detect } from "detect-browser"

export let socket: Socket;

async function socketService(dispatch: any) {
  const token = localStorage.getItem("token");
  let deviceId = localStorage.getItem("deviceId");
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem("deviceId", deviceId);
  }
  let reconnect = false;

  const browser = detect()
  socket = io(`${SocketUrl + token}&type=web&deviceId=${deviceId}&buildId=${browser.name}-${browser.version}&OSVersion=${browser.os}`, {
    extraHeaders: {
      "ngrok-skip-browser-warning": "69420",
    },
    path: "/socket.io",
    forceNew: false,
    reconnectionAttempts: Infinity,
    timeout: 20000, // here replaced 200000 to 20000
  });

  socket.on("connect", () => {
    console.log("-- socket connected --");

    const { userData }: any = store.getState().user
    const { activeRoomData, activeChatsLimit, activeRoomChats } =
      store.getState().chat;

    if (activeRoomData?._id) {
      const unreadMsgs = activeRoomData?.participants?.find(el => el?.user_id == userData?._id)?.unread_cid
     
      emit("setChatReadBy", {
        roomId: activeRoomData?._id,
        cid: unreadMsgs,
      });

      refreshTokenCllient(token)
        .query({
          query: GET_CHATS_BY_ROOM_ID,
          variables: {
            input: {
              roomId: activeRoomData?._id, // roomId
              total: activeRoomData?.totalChats, // total chats
              index:
                activeRoomChats.length > 0
                  ? activeRoomChats[0]?.index
                  : activeRoomData?.totalChats + 1,
              limit: activeChatsLimit, // custom limit
              scrollType: "LATEST", // "UP"
            },
          },
        })
        .then((res) => {
          if (res.data.getChatsByRoomId.length > 0) {
            dispatch(setActiveRoomsChats(res?.data?.getChatsByRoomId));
          }
        })
        .catch((error) => {
          console.log(`Cannot Query scenario by id => ${error}`);
        });
    }

    authCllient
      .query({
        query: GET_ON_GOING_CALLS,
      })
      .then((res) => {
        const roomIds = [];

        res?.data?.getOnGoingCalls?.forEach((item: any) => {
          const count = item?.callParticipants?.filter(
            (elem: any) => elem?.callStatus === "accepted"
          );
          if (count?.length > 1) {
            roomIds.push(item?.roomId?._id);
          }
        });

        res?.data?.getOnGoingCalls?.forEach((item: any) => {
          item?.callParticipants?.forEach((elem: any) => {
            if (
              elem?.userId?._id === userData?._id &&
              elem?.callStatus === "accepted"
            ) {
              // dispatch(setJoinedFromOtherDevice(true))
            }
          });
        });

        dispatch(setParticipantsInCall(roomIds));
        dispatch(setOnGoingCallData(res?.data?.getOnGoingCalls));
      })
      .catch((error) => console.log("error query at on going call -> ", error));
  });

  const getProfileData = async (data) => {
    dispatch(setIsRoomsLoading(true))
    dispatch(setIsContactsLoading(true))
    try {
      const response = await authCllient.query({ query: ME })
      const result = response?.data?.me
      if (result) {
        const user = {
          ...data,
          language: result?.language
        }
        dispatch(setUserData(user));
        const oldDevice = localStorage.getItem("deviceId");
        if (oldDevice != result?.WebDeviceId) {
          removeWebDevice()
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(setIsRoomsLoading(false))
      dispatch(setIsContactsLoading(false))
    }
  }

  const removeWebDevice = async () => {
    let deviceId = localStorage.getItem("deviceId");
    await authCllient
      .query({
        query: LOGOUT,
        variables: {
          input: {
            plateform: "WEB",
            token: deviceId,
          },
        },
      })
      .then((res) => {
        dispatch(resetChat(store.getState().chat));
        dispatch(resetOrg(store.getState().organization));
        dispatch(resetUser(store.getState().user));
        localStorage.clear();
      })
      .catch((error) => console.log(error));
  };

  socket.on("disconnect", (reason: any) => {
    reconnect = true;
    console.log("-- socket disconnected --", reason);
  });

  socket.on("createRoom", (data: any) => {
    const { rooms } = store.getState().chat;
    const getRoomById = rooms?.find(
      (item: any) => item?._id === data?.msg?.roomId
    );
    dispatch(setActiveRoomData(getRoomById));
    emit("getChatsByRoomId", { roomId: data?.msg?.roomId });
    emit("getUserMediaByRoomId", { roomId: data?.msg?.roomId, type: "media" });
  });

  socket.on("message", (data: any) => {

    if (data?.type == "logoutWeb") {
      removeWebDevice()
    }

    const { callisRunning, activeCallData } = store.getState().chat;
    if (data.type === "profile") {
      socket.emit("GetAllRooms");
      getProfileData(data.msg)
    }

    if (data.type === "GetAllRooms") {
      const filteredData = data?.msg?.rooms.filter(
        (item: any) => item?.organization === ""
      );
      setTimeout(() => {
        dispatch(setIsRoomsLoading(false));
      }, 2000);
      dispatch(setRooms(filteredData));

      // dispatch(setIsContactsLoading(false));
    }

    if (data.type === "TASK_NOTIFICATION") {
      const { currentAssignment } = store.getState().organization;
      authCllient
        .query({
          query: getMyNotifications,
          variables: {
            input: {
              skip: 0,
              limit: 100,
              assignmentId: currentAssignment?._id,
            },
          },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          let count = 0;
          if (res?.data?.getMyNotifications?.data.length > 0) {
            res.data?.getMyNotifications?.data.forEach((element: any) => {
              if (!element.isSeen) {
                count = count + 1;
              }
            });
          }
          dispatch(setNotificationArray(res?.data?.getMyNotifications?.data));
          dispatch(setNotificationCount(count));
        });
    }

    if (data.type === "getChatsByRoomId") {
      const { activeRoomData, activeRoomChats } = store.getState().chat;
      // console.log(activeRoomChats,data.msg.chats)
      if (
        activeRoomChats.filter(
          (item: any) =>
            item.index === data?.msg?.chats[data.msg.chats.length - 1]?.index
        ).length === 0
      ) {
        if (activeRoomData?._id !== undefined && reconnect) {
          dispatch(setActiveRoomsChats(data.msg.chats));
        } else {
          if (activeRoomChats.length === 0) {
            dispatch(appendOldChats(data.msg.chats));
          } else if (
            data.msg.chats &&
            data.msg.chats[0] &&
            data.msg.chats[0]?.index &&
            data.msg.chats[0].index >
              activeRoomChats[activeRoomChats.length - 1].index
          ) {
            dispatch(appendPreviousChats(data.msg.chats));
          } else {
            dispatch(setActiveRoomChats(data.msg.chats));
          }
        }
      } else {
      }
      if (data.msg.chats.length > 0) {
        const { roomsSession } = store.getState().chat;
        let temp = {};
        temp = { ...roomsSession, [data.msg.chats[0].roomId]: data.msg.chats };
        dispatch(setRoomsSession(temp));
      }
      socket.emit("getPinChats", { roomId: activeRoomData?._id });
      socket.emit("getFavouriteChats", { roomId: activeRoomData?._id });
    }

    if (data.type === "getPinChats") {
      dispatch(setPinnedChats(data?.msg));
    }

    if (data.type === "pinChat") {
      const { pinnedChats, activeRoomChats } = store.getState().chat;
      let temp = [...pinnedChats];
      const obj = activeRoomChats?.find(
        (item: any) => item?._id === data?.data?.cid[0]
      );
      temp.push(obj);
      dispatch(setPinnedChats(temp));
    }

    if (data.type === "unpinChat") {
      const { pinnedChats } = store.getState().chat;
      const temp = pinnedChats?.filter(
        (item: any) => !item?._id?.includes(data?.data?.cid)
      );
      dispatch(setPinnedChats(temp));
    }

    if (data.type === "addChatsToFavourite") {
      const { favouriteChats, activeRoomChats } = store.getState().chat;
      let temp = [...favouriteChats];
      const obj = activeRoomChats?.find(
        (item: any) => item?._id === data?.msg?.cid
      );
      temp.push(obj);
      dispatch(setFavouriteChats(temp));

      const updatedChats = activeRoomChats?.map((one: any) => {
        if (one?._id === data?.msg?.cid) {
          return {
            ...one,
            favourite_by: [1],
          };
        }
        return one;
      });

      dispatch(appendOldChats(updatedChats));
    }

    if (data.type === "removeChatsFromFavourite") {
      const { favouriteChats, activeRoomChats } = store.getState().chat;
      const temp = favouriteChats?.filter((item: any) =>
        item?._id
          ? item?._id !== data?.data?.cid[0]
          : item?.cid !== data?.data?.cid[0]
      );
      dispatch(setFavouriteChats(temp));

      const updatedChats = activeRoomChats?.map((one: any) => {
        if (one?._id === data?.data?.cid[0]) {
          return {
            ...one,
            favourite_by: [],
          };
        }
        return one;
      });

      dispatch(appendOldChats(updatedChats));
    }

    if (data.type === "sendChat" || data.type === "sendBroadcastChat") {
      const { activeRoomData, activeRoomChats } = store.getState().chat;
      const isExist = activeRoomChats.filter((item:any) => item._id === data?.msg?._id);
    
      let tempActiveRoomData = { ...activeRoomData };
      tempActiveRoomData["totalChats"] += 1;
      if (tempActiveRoomData?._id) {
      dispatch(setActiveRoomData(tempActiveRoomData));}

      if (ChatAction[data?.msg?.type?.toUpperCase()]) {
        console.log("sendChat", data?.msg)
        dispatch(updateActiveRoomChats(data?.msg));
      }
      
      // if (ChatAction[data?.msg?.type?.toUpperCase()]) {
      //   if (data?.msg.roomId === activeRoomData?._id && isExist.length === 0) {
      //     if (activeRoomChats.length > 0) {
      //       if (activeRoomChats[activeRoomChats.length - 1]?.index === activeRoomData.totalChats) {
      //         dispatch(updateActiveRoomChats(data?.msg));
      //       }
      //     } else {
      //       dispatch(updateActiveRoomChats(data?.msg));
      //     }
      //   }
      // } else if (data.msg.isForwarded === true) {
      //   if (data?.msg.roomId === activeRoomData?._id && isExist.length === 0) {
      //     dispatch(updateActiveRoomChats(data.msg))
      //   }
      // } else {
      //   dispatch(
      //     replaceMessageWithLocalId({ id: data?.local_Id, newItem: data?.msg })
      //   );
      // }
    }

    if (data.type === "getRoomInComon") {
      dispatch(setGroupsInCommon(data.msg));
    }

    if (data.type === "onBlockRoom" || data.type === "blockRoom") {
      const { activeRoomData, rooms } = store.getState().chat;
      const { userData } = store.getState().user;
      const tempActiveRoomData: any = { ...activeRoomData };

      const updatedParticipants = tempActiveRoomData?.participants?.map(
        (one: any) => {
          if (one?.user_id === data?.msg?.user_id) {
            return { ...one, left_at: data?.msg?.time };
          }
          return one;
        }
      );
      const temp = [];
      temp.push({
        room_Id: data?.msg?.room_Id || data?.msg?.room_id,
        pid: data?.msg?.pid,
      });
      dispatch(setUserData({ ...userData, blockedRooms: temp }));
      tempActiveRoomData.participants = updatedParticipants;
      if (activeRoomData?._id) {
        dispatch(setActiveRoomData(tempActiveRoomData));
      }
    }

    if (data.type === "onUnblockRoom" || data.type === "unblockRoom") {
      const { activeRoomData } = store.getState().chat;
      const tempActiveRoomData: any = { ...activeRoomData };
      socket.emit("getProfile");
      const updatedParticipants = tempActiveRoomData?.participants?.map(
        (one: any) => {
          if (one?.user_id == data?.msg?._id) {
            return { ...one, left_at: 0 };
          }
          return one;
        }
      );
      tempActiveRoomData.participants = updatedParticipants;
      if (activeRoomData?._id) {
        dispatch(setActiveRoomData(tempActiveRoomData));
      }
    }

    if (data.type === "changeRoomPermission") {
      const { activeRoomData } = store.getState().chat;
      const newData = { ...activeRoomData };
      newData.access = data?.msg;

      dispatch(setActiveRoomData(newData));
    }

    if (data.type === "OnChangeRoomPermission") {
      const { activeRoomData } = store.getState().chat;
      const newData = { ...activeRoomData };
      newData.access = data?.msg?.access;

      dispatch(setActiveRoomData(newData));
    }

    if (data.type === "onUpdateRoomAdmin") {
      const { activeRoomData } = store.getState().chat;
      const tempActiveRoomData: any = { ...activeRoomData };
      if (activeRoomData) {
        const updatedParticipants = tempActiveRoomData?.participants?.map(
          (one: any) => {
            if (one?.user_id === data?.msg?.pid) {
              return {
                ...one,
                user_type: data.msg.user_type,
              };
            }
            return one;
          }
        );
        tempActiveRoomData.participants = updatedParticipants;

        dispatch(setActiveRoomData(tempActiveRoomData));
      }
    }

    if (data.type === "changeRoomWallpaper") {
      const { activeRoomData } = store.getState().chat;
      const tempActiveRoomData: any = { ...activeRoomData };
      if (activeRoomData?._id === data?.data?.roomId) {
        const updatedParticipants = tempActiveRoomData?.participants?.map(
          (one: any) => {
            if (one?.user_id === data?.data?.userId) {
              return {
                ...one,
                wallpaper: {
                  fileName: data?.data?.fileName,
                  opacity: data?.data?.opacity,
                },
              };
            }
            return one;
          }
        );
        tempActiveRoomData.participants = updatedParticipants;
        dispatch(setActiveRoomData(tempActiveRoomData));
      }
    }

    if (data.type === "getUserMediaByRoomId") {
      dispatch(setUserMediaByRoomId(data?.msg?.data));
    }
    if (data.type === "onUserPictureChange") {
      const { rooms } = store.getState().chat;
      const { userData }: any = store.getState().user;
      let finalArrayAfterProfileUpdate = [];
      if (rooms.length > 0) {
        rooms.forEach((element: any) => {
          if (element._id === data?.msg?.message?.roomId) {
            let copyElement = { ...element };
            let temp = [];
            element.participants.forEach((item: any) => {
              if (item.user_id === userData._id) {
                temp.push({
                  ...item,
                  ["profile_img"]: data?.msg?.message?.profile_img,
                });
              } else {
                temp.push(item);
              }
            });
            finalArrayAfterProfileUpdate.push({
              ...copyElement,
              participants: temp,
            });
          } else {
            finalArrayAfterProfileUpdate.push(element);
          }
        });
      }
      dispatch(setRooms(finalArrayAfterProfileUpdate));
    }

    if (data.type == "MESSAGE_DELIVERED") {
      const { activeRoomData }: any = store.getState();
      const jsond = data?.msg?.payload ? JSON.parse(data?.msg?.payload) : null;
      const jsond2 = jsond ? JSON.parse(jsond?.data) : null;
      if (jsond2?.roomId == activeRoomData?._id) {
        emit("getChatsByRoomId", { roomId: jsond2?.roomId });
      }
    }

    if (
      data.type === "archiveRoom" ||
      data.type === "unArchiveRoom" ||
      data.type === "unmuteRoom" ||
      data.type === "muteRoom" ||
      data.type === "fixRoom" ||
      data.type === "unfixRoom" ||
      data.type === "clearAllChats" ||
      data.type === "deleteRoom" ||
      data.type === "markRoomUnread" ||
      data.type === "setChatReadBy" ||
      data.type === "sendChat" ||
      data.type === "MESSAGE_DELIVERED" ||
      data.type === "sendBroadcastChat" ||
      // data.type === "MarkSeen" ||
      data.type === "joinRoom" ||
      data.type === "addRoomToUser" ||
      data.type === "deleteChat" ||
      data.type === "onChatDelete" ||
      data.type === "forwardChat" ||
      data.type === "deleteRoomPermanently" ||
      data.type === "onBlockRoom" ||
      data.type === "onUnblockRoom" ||
      data.type === "removedUser" ||
      data.type === "removeUserFromRoom" ||
      data.type === "onJoinRoom"
    ) {
      const { activeRoomData } = store.getState().chat;
      if (
        activeRoomData?._id &&
        (data?.type != "deleteRoom" || data?.type != "deleteRoomPermanently")
      ) {
        emit("GetRoomById", { roomId: activeRoomData?._id });
      }
      socket.emit("GetAllRooms");
    }

    if (data.type === "clearAllChats") {
      const { activeRoomData } = store.getState().chat;
      if (activeRoomData?._id === data?.msg?.roomId) {
        socket.emit("getPinChats", { roomId: activeRoomData?._id });
        socket.emit("getFavouriteChats", { roomId: activeRoomData?._id });
        dispatch(setActiveRoomChats([]));
        dispatch(appendOldChats([]));
      }
    }

    if (
      (data?.type === "sendChat" || data?.type === "sendBroadcastChat") &&
      data?.msg?.isSent === true
    ) {
      if (!ChatAction[data?.msg?.type.toUpperCase()]) {
        const audio = new Audio(SendChatAuio);
        audio.play();
      }
    }

    if (data.type === "exportChatsByRoomId") {
      window.open(DefaultImageUrl + data.msg.filename);
    }

    if (data.type === "getFavouriteChats") {
      dispatch(setFavouriteChats(data.msg));
    }

    if (
      data.type === "addRoomToFolder" ||
      data.type === "createFolder" ||
      data.type === "deleteFolder" ||
      data.type === "reArrangeFolder" ||
      data.type === "editFolder" ||
      data.type === "reArrangeFolder" ||
      data.type === "blockRoom" ||
      data.type === "unblockRoom"
    ) {
      socket.emit("getProfile");
    }

    if (data.type === "online") {
      /*  socket.emit("sendStatusToAUser", {
         type: "online",
         user_id: data?.msg?.user_id,
       }); */
      const { onlineRooms } = store.getState().chat;
      const { userData }: any = store.getState().user;
      const temp = [...onlineRooms];
      if (
        !temp.includes(data?.msg.roomId) &&
        data?.msg?.user_id !== userData?._id
      ) {
        temp.push(data?.msg.roomId);
      }
      dispatch(setOnlineRooms(temp));
      if (data?.msg?.user_id === userData?._id) {
        dispatch(setUserOnlineStatus(data));
      }
    }
    if (data.type === "offline") {
      const { onlineRooms } = store.getState().chat;
      const temp = onlineRooms.filter(
        (item: any) => item !== data?.msg?.roomId
      );
      dispatch(setOnlineRooms(temp));
    }
    if (data.type === "ack_online") {
      dispatch(setUserOnlineStatus(data));
    }

    if (!data.type.includes("online") && !data.type.includes("offline")) {
      // console.log('Socket Event************', data)
    }

    if (data.type === "searchChatsByRoomId") {
      dispatch(setSearchChats(data?.msg?.chats));
    }

    if (data.type === "sync_chat") {
      //   console.log('sync_chat************', data)
      const { activeRoomData, activeRoomChats, newMessageCount } =
        store.getState().chat;
      const { userData, isTabActive }: any = store.getState().user;

      const msg = JSON.parse(data?.msg?.message);
      const isExist = activeRoomChats?.some(
        (item: any) => item?._id === msg?._id
      );
      dispatch(setScrollBottom(true));
      if (!isExist) {
        if (activeRoomData?._id === msg?.roomId) {
          let tempActiveRoomData = { ...activeRoomData };
          tempActiveRoomData["totalChats"] += 1;
          let temp = [...activeRoomChats, msg];
          //  dispatch(setActiveRoomData(tempActiveRoomData));
          dispatch(resetActiveRoomChats(temp));

          //  console.log(activeRoomData?._id, msg?.roomId, msg?.sender, userData?._id, '******')
          if (
            activeRoomData?._id === msg?.roomId &&
            msg?.sender !== userData?._id
          ) {
            dispatch(setNewMessageCount(newMessageCount + 1));
          }

          socket.emit("setChatDelivered", {
            roomId: msg?.roomId,
            cid: msg?._id,
          });
          if (isTabActive) {
            socket.emit("setChatReadBy", {
              roomId: msg?.roomId,
              cid: msg?._id,
            });
          }
        }
        emit("GetAllRooms");
      }
    }

    if (data.type === "onDeliveredChat") {
      const { activeRoomData, activeRoomChats, newMessageCount } =
        store.getState().chat;
      const msg = data?.msg;
      if (activeRoomData?._id === msg?.roomId) {
        /* let temp=[...activeRoomChats];
        let copy={...temp.filter((item:any)=>item._id===msg.cid)[0]};
        let allCopy=temp.filter((item:any)=>item._id!==msg.cid);
        copy['delivered_to']=[copy.delivered_to,msg.delivered_to];
        let final=[...allCopy];
        final.push(copy);
        dispatch(setActiveRoomsChats(final)) */
        /*  activeRoomChats.forEach((element:any) => {
          
           if(element._id===msg.cid){
             if(element?.delivered_to.filter((el:any)=>el?.user_id!==msg?.delivered_to?.user_id)){
             ids.push(msg.delivered_to);
           }
           const copy={...element};
             copy['delivered_to']=ids;
           }
         }); */

        const newArr = activeRoomChats.map((obj: any) => {
          let ids = [...obj.delivered_to];

          if (
            obj?.delivered_to.filter(
              (el: any) => el?.user_id === msg?.delivered_to?.user_id
            ).length === 0
          ) {
            ids.push(msg?.delivered_to);
          }
          //if (obj._id===msg.cid) {
          return { ...obj, delivered_to: ids };
          // }

          //return obj;
        });
        dispatch(setActiveRoomsChats(newArr));
      }
    }
    if (data.type === "MarkSeen") {
      const { activeRoomData, activeRoomChats, newMessageCount } =
        store.getState().chat;
      const msg = data?.msg;
      if (activeRoomData?._id === msg?.roomId) {
        /* let temp=[...activeRoomChats];
        let copy={...temp.filter((item:any)=>msg.cid.includes(item._id))[0]};
        let allCopy=temp.filter((item:any)=>!msg.cid.includes(item._id));
        copy['read_by']=[copy.read_by,msg.data];
        let final=[...allCopy];
        final.push(copy);
        dispatch(setActiveRoomsChats(final)) */
        const newArr = activeRoomChats.map((obj: any) => {
          let ids = [...obj.read_by];

          if (
            obj?.read_by.filter((el: any) => el?.user_id === msg?.data?.user_id)
              .length === 0
          ) {
            ids.push(msg.data);
          }
          if (obj._id === msg.cid[0]) {
            return { ...obj, read_by: ids };
          } else {
            return { ...obj };
          }

          // return obj;
        });
        dispatch(setActiveRoomsChats(newArr));
      }
    }

    if (data.type === "onDeleteChat") {
      const { activeRoomData, activeRoomChats } = store.getState().chat;
      const { userData }: any = store.getState().user;
      const msg = data?.msg;
      const isExist = activeRoomChats?.filter(
        (item: any) => item?._id === msg?.cid
      );
      dispatch(setScrollBottom(true));
      // let chat=[...activeRoomChats];
      if (isExist) {
        if (activeRoomData?._id === msg?.roomId) {
          // chat.pop();
          let remaining_chat = activeRoomChats.filter(
            (item) => item._id !== msg.cid
          );
          let temp = { ...isExist[0] };
          temp["deleted"] = [
            {
              deleted_at: 1682680476181,
              type: "everyone",
              user_id: userData?._id,
            },
          ];
          temp["message"] = "This message was deleted";
          let final = [...remaining_chat, temp];
          dispatch(resetActiveRoomChats(final));
        }
        emit("GetAllRooms");
      }
    }

    if (data.type === "invited") {
      const { invites } = store.getState().organization;
      if (invites.length > 0) {
        if (
          invites.filter((item: any) => item._id === data?.msg._id).length === 0
        )
          dispatch(updateInvites(data?.msg));
      } else {
        dispatch(updateInvites(data?.msg));
      }
    }

    if (data?.type == "deleteInvite") {
      const { invites } = store.getState().organization;
      const updated = invites?.filter(
        (item) => item?._id != data?.msg?.inviteId
      );
      dispatch(setOrganizationInvite(updated));
    }

    if (data.type === "assignment") {
      const { assignments, currentOrganization } =
        store.getState().organization;
      const { userData }: any = store.getState().user;
      if (
        currentOrganization?._id === data?.msg?.organizationId &&
        data?.msg?.assignBy?._id !== userData?._id
      ) {
        dispatch(updateAssignments(data?.msg));
      }
    }

    if (data.type === "assignmentDeleted") {
      const { assignments, currentAssignment } = store.getState().organization;

      if (currentAssignment?._id === data?.msg?.assignmentId) {
        dispatch(setShowTaskPage(false));
        /* dispatch(setActiveRoomData({}));
        dispatch(setActiveRoomChats([])); */
      }

      let temp = [...assignments];

      const updated = temp?.filter(
        (item) => item?._id !== data?.msg?.assignmentId
      );

      dispatch(setAssignments(updated));
    }

    if (data.type === "approvedTask") {
      const { currentReport, scenario, currentOrganization } =
        store.getState().organization;
      if (
        scenario.tasks.filter((el: any) => el._id === data?.msg?.taskId)
          .length > 0 &&
        currentOrganization?._id === data?.msg?.organizationId
      ) {
        const updatedData = currentReport?.tasksData?.map(
          (item: any, index: number) => {
            if (index === currentReport?.tasksData?.length - 1) {
              return {
                ...item,
                isApproved: true,
              };
            }
            return item;
          }
        );

        let updatedReport = { ...currentReport, tasksData: updatedData };
        dispatch(setCurrentReport(updatedReport));
      }
    }

    if (data.type === "unApprovedTask") {
      const { currentReport, scenario, currentOrganization } =
        store.getState().organization;
      if (
        scenario.tasks.filter((el: any) => el._id === data?.msg?.taskId)
          .length > 0 &&
        currentOrganization?._id === data?.msg?.organizationId
      ) {
        const updatedData = currentReport?.tasksData?.filter(
          (item: any) => item?.taskId !== data?.msg?.taskId
        );

        let updatedReport = { ...currentReport, tasksData: updatedData };
        dispatch(setCurrentReport(updatedReport));
      }
    }

    if (data.type === "CALL") {
      if (localStorage.getItem("token")) {
        const { onGoingCallData, joinedFromOtherDevice, isCreateCall } = store.getState().chat;
        const jsonData = JSON.parse(data?.msg);
        const { userData }: any = store.getState().user;
        let tempOnGoingData = [...onGoingCallData];
        tempOnGoingData.push(jsonData?.data);
        dispatch(setOnGoingCallData(tempOnGoingData));
        dispatch(setRejectCallData(""));
        dispatch(getCallHistory(false));
        // if (jsonData?.data?.origin?._id == userData?._id) {
        //   dispatch(setJoinedFromOtherDevice(true));
        // }
        if (!callisRunning) {
          dispatch(setActiveCallData(JSON.parse(data.msg)));
          if (
            jsonData?.data?.origin?._id === userData?._id &&
            joinedFromOtherDevice === false
          ) {
            if (isCreateCall === false) {
            } else {
              dispatch(updateCallStatus(true));
            }
          }
        }
      }
    }

    if (data.type === "LEFT_CALL") {
      const newData = JSON.parse(data?.msg);
      if (activeCallData?.data?.channelName === newData?.data?.channelName) {
        dispatch(setLeftCallData(JSON.parse(data.msg)));
      }

      localStorage.removeItem("uid");
    }

    if (data.type === "REJECT_CALL") {
      const { memberAddedInCall }: any = store.getState().chat;
      const newData = JSON.parse(data?.msg);
      if (activeCallData?.data?.channelName === newData?.data?.channelName) {
        dispatch(
          setMemberAddedInCall(
            memberAddedInCall.filter((el: any) => el !== newData?.userId)
          )
        );
        dispatch(
          setActiveCallData({
            callType: activeCallData?.callType,
            data: {
              ...activeCallData.data,
              callParticipants: newData?.data?.callParticipants,
            },
          })
        );
        dispatch(setRejectCallData(JSON.parse(data.msg)));
      }
      localStorage.removeItem("uid");
    }
    /*  if (data.type === "ParticipantAdded") {
       if (activeCallData?.data?.callId === data?.msg?.callId) {
           console.log(data,'ParticipantAdded',activeCallData);
           dispatch(setActiveCallData({callType:activeCallData?.callType,data:{...activeCallData.data,callParticipants:data?.msg?.userForWeb}}))
       }
     }
  */
    if (data.type === "Call Ended!") {
      const { onGoingCallData, isParticipantsInCall }: any =
        store.getState().chat;
      const newData = JSON.parse(data?.msg);
      if (activeCallData?.data?.channelName === newData?.data?.channelName) {
        dispatch(setActiveCallData(JSON.parse(data.msg)));
      }
      dispatch(setJoinedFromOtherDevice(false));
      dispatch(getCallHistory(true));
      dispatch(updateCreateCall(false));
      // for on going call -->
      const updatedOnGoingData =
        onGoingCallData.length > 0
          ? onGoingCallData?.filter(
              (item: any) => item?._id !== newData?.data?.callId
            )
          : [];
      dispatch(setOnGoingCallData(updatedOnGoingData));

      const updatedParticipantsInCall = isParticipantsInCall?.filter(
        (item: any) => item !== newData?.data?.roomId?._id
      );
      localStorage.removeItem("uid");
      dispatch(setParticipantsInCall(updatedParticipantsInCall));
    }

    if (data.type === "OnAccept") {
      const { userData }: any = store.getState().user;
      const {
        isParticipantsInCall,
        memberAddedInCall,
        onGoingCallData,
        activeRoomData,
      } = store.getState().chat;
      const jsonData = JSON.parse(data?.msg);
      const onGoingCallRoomData =
        onGoingCallData?.length > 0
          ? onGoingCallData?.find(
              (el: any) => el.roomId?._id === activeRoomData?._id
            )
          : [];
      let tempIsParticipants = [...isParticipantsInCall];

      const count = jsonData?.data?.callParticipants?.filter(
        (item: any) => item?.callStatus === "accepted"
      );
      if (
        activeCallData?.data?.callId === jsonData?.data?.callId ||
        activeCallData?.data?._id === jsonData?.data?.callId
      ) {
        dispatch(
          setMemberAddedInCall(
            memberAddedInCall.filter((el: any) => el !== jsonData?.userId)
          )
        );
        dispatch(
          setActiveCallData({
            ...activeCallData,
            data: {
              ...activeCallData?.data,
              callParticipants: jsonData?.data?.callParticipants,
            },
          })
        );
        if (
          onGoingCallData.filter(
            (el: any) => el?._id === jsonData?.data?.callId
          ).length > 0
        ) {
          dispatch(
            setOnGoingCallData([
              ...onGoingCallData,
              {
                ...onGoingCallData.find(
                  (el: any) => el?._id === jsonData?.data?.callId
                ),
                callParticipants: jsonData?.data?.callParticipants,
              },
            ])
          );
        }
      }
      if (count?.length > 0) {
        if (!tempIsParticipants?.includes(jsonData?.data?.roomId)) {
          tempIsParticipants.push(jsonData?.data?.roomId);
        }
      }

      jsonData?.data?.callParticipants?.forEach((item: any) => {
        if (
          item?.userId?._id === userData?._id &&
          item?.callStatus === "accepted"
        ) {
          dispatch(setJoinedFromOtherDevice(true));
        }
      });

      if (activeCallData) {
        dispatch(setParticipantsInCall(tempIsParticipants));
      }
    }

    if (data?.type === "newUser") {
      const { userContacts }: any = store.getState().user;
      const { comonUsers }: any = store.getState().user;
      let newContact = userContacts.map((element: any) => {
        if (element.phone === data?.msg?.phone) {
          let temp = { ...element };
          temp["userId"] = data?.msg?.user;
          const newContactArray = [...comonUsers];
          newContactArray.push(temp);
          dispatch(setComonUsers(newContactArray));
        }
      });
      //  dispatch(setUserContacts(newContact));
    }
  });

  socket.on("newMessage", (data: any) => {
    const { activeRoomData, activeRoomChats, newMessageCount } =
      store.getState().chat;
    const { userData, isTabActive }: any = store.getState().user;
    if (data.type === "newMessage") {
      const msg = JSON.parse(data?.msg?.message);
      const isExist = activeRoomChats?.some(
        (item: any) => item?._id === msg?._id
      );
      dispatch(setScrollBottom(true));
      if (!isExist) {
        if (activeRoomData) {
          const id = activeRoomChats.length > 0 ? activeRoomChats[activeRoomChats.length - 1]?._id : "";
          if (id !== "" && isTabActive) {
            emit("setChatReadBy", {
              roomId: activeRoomData?._id,
              cid: msg?._id,
            });
          }
        }
        socket.emit("setChatDelivered", {
          roomId: msg?.roomId,
          cid: msg?._id,
        });
        if (activeRoomData?._id === msg?.roomId) {
          // if (activeRoomChats[activeRoomChats.length - 1]?.index === activeRoomData.totalChats) {
            console.log("newMessage andar", msg)
            let tempActiveRoomData = { ...activeRoomData };
            tempActiveRoomData["totalChats"] += 1;
            dispatch(setActiveRoomData(tempActiveRoomData));
            dispatch(updateActiveRoomChats(msg));

          // }
          if (activeRoomData?._id === msg?.roomId && msg?.sender !== userData?._id) {
            dispatch(setNewMessageCount(newMessageCount + 1));
          }
        }
        emit("GetAllRooms");
      }
    }
  });

  socket.on("archive", (data: any) => {
    const { organizations, currentOrganization, assignments, invites } = store.getState()?.organization

    console.log("archive task panel data", data)

    if (data?.type == "archiveOrganization") {
      const filteredOrgs = organizations?.filter((el: any) => el?._id != data?.msg)
      dispatch(setOrganizations(filteredOrgs))

      const filteredInvites = invites?.filter((el: any) => el?.organization?._id != data?.msg)
      dispatch(setOrganizationInvite(filteredInvites))
      
      if (currentOrganization?._id == data?.msg) {
        dispatch(setCurrentOrganization({}))
      }
    }

    if (data?.type == "archiveAssignment") {
      const filteredAssignments = assignments?.filter((item: any) => item?._id != data?.msg)
      dispatch(setAssignments(filteredAssignments))
    }
  })

  socket.on("error", (data: any) => {
    console.log("error message = ", data);
  });
}

function emit(evt: string, data?: any) {
  socket?.emit(evt, data);
}

function socketListen(event: string, cb: any) {
  socket?.on(event, cb);
}

function socketDisconnect() {
  socket.disconnect();
}
export { emit, socketDisconnect, socketListen, socketService };
