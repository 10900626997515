import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Tab, Tabs } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DefaultImageUrl } from "../../Constant/Constant";
import ArrowIcon from "../../Assets/Images/Arrow.png";
import ShareLinkIcon from "../../Assets/Images/ShareLinkIcon.png";
import ShareDocsIcon from "../../Assets/Images/SharedDocsIcon.png";
import { emit } from "../../Socket";
import audio from '../../Assets/Images/AudioFileIcon.png'
import {
  SharedMediaDataTypes,
  SharedMediaMessagesDataTypes,
} from "../../Types/Types";
import moment from "moment";
import { useTranslation } from "react-i18next";

const SharedMedia = ({
  setMediaDrawerShow,
  setShowMediaPreview,
  setSelectedMedia,
}: any) => {
  const [tab, setTab] = useState(0);
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true);
  const [tabType, setTabType] = useState("media");
  const { userMediaByRoomId, activeRoomData, activeRoomChats } = useSelector(
    (state: any) => state.chat
  );

  // useEffect(() => {
  //   emit("getUserMediaByRoomId", {
  //     roomId: activeRoomData?._id,
  //     skip: 0,
  //     limit: 25,
  //     type: tabType,
  //   });
  // }, [tabType]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }, [userMediaByRoomId]);
  useEffect(() => {
    if (tab === 0) {
      setTabType("media");
    } else if (tab === 1) {
      setTabType("documents");
    } else {
      setTabType("link");
    }
  }, [tab, setTab]);

  const backFunc = () => {
    setMediaDrawerShow(false);
  };

  console.log(activeRoomChats?.filter((item: any) => item.type == 'AUDIO' || item.type === 'Audio'))
  return (
    <div>
      <div className="flex py-2 border-b px-2 h-[60px] bg-[#F3F9FC]">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => backFunc()}
        >
          <div>
            <ArrowBackIosIcon sx={{ fontSize: "18px" }} />
          </div>
          <div className="pt-[1px]">{t("back")}</div>
        </div>
      </div>

      <div className="px-4 w-full">
        <Tabs
          aria-label="basic tabs example"
          value={tab}
          onChange={(event: SyntheticEvent, newValue: number) => {
            setLoading(true);
            setTab(newValue);
          }}
        >
          <Tab label={t("room_media")} sx={{ width: "25%" }} />
          <Tab label={t("room_docs")} sx={{ width: "25%" }} />
          <Tab label={t("room_links")} sx={{ width: "25%" }} />
          <Tab label={t("audio")} sx={{ width: "25%" }} />
        </Tabs>
      </div>

      <div className="mt-3 px-4">
        {tab === 0 && (
          <div>
            <div className="textGray">
              {activeRoomChats?.filter((item: any) => item.type == 'IMAGE' || item.type === 'VIDEO').length > 0 && activeRoomChats.length > 0
                ? moment(new Date()).format("MMMM") ===
                  moment(activeRoomChats[0]?.created_at).format("MMMM")
                  ? t("this_month")
                  : moment(activeRoomChats[0]?.created_at, "MM").format("MMMM")
                : ""}

              <div className="flex gap-3 flex-wrap">
                {activeRoomChats?.filter((item: any) => item.type == 'IMAGE' || item.type === 'VIDEO').length > 0 ? activeRoomChats?.filter((item: any) => item.type == 'IMAGE' || item.type === 'VIDEO').map(
                  (elem: SharedMediaMessagesDataTypes, key: number) => {
                    const deleted = elem.deleted?.filter(
                      (item: any) => item.type === "everyone"
                    );

                    return (
                      deleted?.length === 0 && (
                        <div
                          className="mt-3 hover:opacity-70 cursor-pointer border-2 border-slate-300"
                          key={key}
                          onClick={() => {
                            setSelectedMedia({
                              src: DefaultImageUrl + elem?.fileURL,
                              type: elem?.type === "IMAGE" ? "image" : "video",
                            });
                            setShowMediaPreview(true);
                          }}
                        >
                          {elem?.type === "IMAGE" ? (
                            <div className="h-[70px] w-[70px] min-w-[70px]">
                              <img
                                src={DefaultImageUrl + elem?.fileURL}
                                alt=""
                                className="h-full w-full rounded-md"
                              />
                            </div>
                          ) : (
                            <div className="h-[70px] w-[70px] min-w-[70px]">
                              <video
                                src={DefaultImageUrl + elem?.fileURL}
                                width="70px"
                                style={{ height: "inherit" }}
                                className="rounded-md w-[120px]"
                              ></video>
                            </div>
                          )}
                        </div>
                      )
                    );
                  }
                ) : <div className="textGray text-center"> {t("no_media")} </div>}
              </div>
            </div>
          </div>
        )}

        {tab === 1 && (
          <div>
            <div>
              <div className="textGray">
                {activeRoomChats?.filter((item: any) => item.type === "DOCUMENT" || item.type == 'APPLICATION').length > 0 && activeRoomChats.length > 0
                  ? moment(new Date()).format("MMMM") ===
                    moment(activeRoomChats[0]?.created_at).format("MMMM")
                    ? t("this_month")
                    : moment(activeRoomChats[0]?.created_at, "MM").format(
                      "MMMM"
                    )
                  : ""}
              </div>

              {activeRoomChats?.filter((item: any) => item.type === "DOCUMENT" || item.type == 'APPLICATION').length > 0
                ? activeRoomChats?.filter((item: any) => item.type === "DOCUMENT" || item.type == 'APPLICATION')?.map(
                  (elem: SharedMediaMessagesDataTypes, key: number) => {
                    const fileName = elem?.fileURL.split("/");
                    const UpdateDocUrl = fileName[fileName.length - 1];

                    return (
                      <div key={key}>
                        {elem?.type !== "IMAGE" && elem.type !== "VIDEO" && (
                          <div>
                            {" "}
                            <div className="mt-3 flex gap-2">
                              <img
                                src={ShareDocsIcon}
                                alt=""
                                className="h-[50px] w-[50px]"
                              />
                              <div
                                className="textGray my-auto"
                              >
                                {UpdateDocUrl}
                              </div>
                            </div>
                            {/* <div className="mt-2 flex justify-between items-center cursor-pointer">
                                            View message
                                            <img
                                              src={ArrowIcon}
                                              alt=""
                                              className="h-[14px]"
                                            />
                                          </div> */}
                          </div>
                        )}
                      </div>
                    );
                  }
                )
                :
                <div className="textGray text-center"> {t("no_docs")} </div>
              }
            </div>
          </div>
        )}

        {tab === 2 && (
          <div>
            <div>
              <div className="textGray">
                {activeRoomChats?.filter((item: any) => item.message.match(/_(.*?)_|\*(.*?)\*|~(.*?)~|```(.*?)```|(https?:\/\/[^\s]+)/g)).length > 0 && activeRoomChats.length > 0
                  ? moment(new Date()).format("MMMM") ===
                    moment(activeRoomChats[0]?.created_at).format("MMMM")
                    ? t("this_month")
                    : moment(activeRoomChats[0]?.created_at, "MM").format(
                      "MMMM"
                    )
                  : ""}
              </div>

              {activeRoomChats?.filter((item: any) => item.message.match(/(https?:\/\/[^\s]+)/g)).length > 0 ? activeRoomChats?.map(
                (elem: SharedMediaMessagesDataTypes, key: number) => {
                  const getLinksRegex =
                    /(https?:\/\/[^\s]+)/g;
                  const getLinks = elem?.message?.match(getLinksRegex);
                  return (
                    <div key={key} className="mt-3">
                      {getLinks?.map((link: string, linkIndex: number) => {
                        return (
                          <div key={linkIndex}>
                            <div className="flex gap-2 items-center">
                              <img
                                src={ShareLinkIcon}
                                alt=""
                                className="h-[50px] w-[50px]"
                              />
                              <div
                                className="cursor-pointer textGray"
                                onClick={() => window.open(link)}
                              >
                                {link}
                              </div>
                            </div>
                            {/* <div className="mt-2 flex justify-between items-center cursor-pointer">
                                          View message
                                          <img
                                            src={ArrowIcon}
                                            alt=""
                                            className="h-[14px]"
                                          />
                                        </div> */}
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              ) : <div className="textGray text-center"> {t("no_links")} </div>}
            </div>
          </div>
        )}
        {tab === 3 && (
          <div>
            <div className="textGray">
              {activeRoomChats?.filter((item: any) => item.type == 'AUDIO' || item.type === 'Audio').length > 0 && activeRoomChats.length > 0
                ? moment(new Date()).format("MMMM") ===
                  moment(activeRoomChats?.filter((item: any) => item.type == 'AUDIO' || item.type === 'Audio')[0]?.created_at).format("MMMM")
                  ? t("this_month")
                  : moment(activeRoomChats?.filter((item: any) => item.type == 'AUDIO' || item.type === 'Audio')[0]?.created_at, "MM").format("MMMM")
                : ""}

              <div className="block gap-3 ">
                {activeRoomChats?.filter((item: any) => item.type == 'AUDIO' || item.type === 'Audio').length > 0 ? activeRoomChats?.filter((item: any) => item.type == 'AUDIO' || item.type === 'Audio').map(
                  (elem: SharedMediaMessagesDataTypes, key: number) => {
                    const deleted = elem.deleted?.filter(
                      (item: any) => item.type === "everyone"
                    );

                    return (
                      deleted?.length === 0 && (
                        <>

                          <div
                            className="mt-3 flex gap-2 rounded-md cursor-pointer bg-slate-200 p-4 border-2 border-slate-300"
                            key={key}
                            onClick={() => {
                              setSelectedMedia({
                                src: DefaultImageUrl + elem?.fileURL,
                                type: "audeo",
                              });
                              setShowMediaPreview(true);
                            }}
                          >
                            <div className="w-8 h-8">
                              <img src={audio} />
                            </div>
                            <div className="text-gray-500">
                              {elem?.fileURL.split('/').pop()}
                            </div>

                          </div>

                        </>
                      )
                    );
                  }
                ) : <div className="textGray text-center"> {t("no_media")} </div>}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SharedMedia;
