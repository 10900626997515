import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StarIcon from "@mui/icons-material/Star";
import { Avatar, Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosShareAlt } from "react-icons/io";
import { useSelector } from "react-redux";
import FileIcon from "../../Assets/Images/FileIcon.png";
import Media from "../../Assets/Images/Media.png";
import PdfFileIcon from "../../Assets/Images/PdfIcon.png";
import PptFileIcon from "../../Assets/Images/PptFileIcon.png";
import TextFileIcon from "../../Assets/Images/TextFileIcon.png";
import DocFileIcon from "../../Assets/Images/WordFileIcon.png";
import XlsxFileIcon from "../../Assets/Images/XlsxFileIcon.png";
import ZipFileIcon from "../../Assets/Images/ZipFileIcon.png";
import { DefaultImageUrl, MessageActions } from "../../Constant/Constant";
import { emit, socketListen } from "../../Socket";
import { MessagesActionDataTypes } from "../../Types/Types";
import Toast from "../../coreComponent/Toast";
import MessageInfo from "./MessageInfo";
import getFileExtension from "../../utils/getFileExtension";
import messageSenderInfo from "../../utils/messageSenderInfo";
const DocumentFilesPreview = ({
  showDate,
  showDateforoutgoing,
  item,
  profileShown,
  setData,
  setSelectedHeading,
  setSelectedHeadingId,
  setShowHeadingModal,
  setMessages,
  messages,
  setChangeFooter,
  setShowCheckbox,
  showCheckbox,
  setReplyFooterShow,
  setReplyMessage,
  setMessageInfoItem,
  setMessageInfoDrawerShow,
}) => {
  const [showMessageActions, setShowMessageActions] = useState(false);
  const [activeFav, setActiveFav] = useState<any>({});
  const anchorRef = useRef<HTMLDivElement>(null);
  const [toastShow, setToastShow] = useState(false);
  const [showArrow, setshowArrow] = useState({ status: false, id: null });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const { topicsData } = useSelector((state: any) => state.user);
  const MessageAction = MessageActions(t);
  const [toastMessage, setToastMessage] = useState("");

  const { userData, userContacts } = useSelector((state: any) => state.user);
  const {
    formattedParticipantsOfActiveRoom,
    activeRoomChats,
    activeRoomData,
    pinnedChats,
  } = useSelector((state: any) => state.chat);
  const userProfileImg =
    activeRoomData?.participants?.length > 0 &&
    activeRoomData?.participants?.find(
      (item: any) => item?.user_id === userData?._id
    );
  const isRoomLeft = userProfileImg?.left_at !== 0;
  const deleted =
    item?.deleted?.filter((element: any) => element.user_id === userData?._id)
      ?.length > 0
      ? false
      : true;
  const senderInfo = formattedParticipantsOfActiveRoom[item.sender];
  const isEveryoneCanPinMessage =
    activeRoomData?.type === "group" &&
    activeRoomData?.access?.some(
      (item: any) => item?.type === "pinMessage" && item?.permit === "common"
    );

    const messageSender = messageSenderInfo(item.sender, activeRoomData?.participants)

  const canYouPinMessage =
    (!isEveryoneCanPinMessage &&
      formattedParticipantsOfActiveRoom[userData?._id]?.user_type ===
        "admin") ||
    isEveryoneCanPinMessage;
  let messageTime =
    item?.created_at !== null
      ? moment(item?.created_at).format("HH:mm")
      : moment(new Date()).format("HH:mm");

  let userDetails = userContacts?.find(
    (item: any) => item?.userId?._id === senderInfo?.user_id
  );

  const isUserBlocked = activeRoomData?.participants?.find(
    (el: any) => el?.left_at !== 0 && el?.user_id === userData?._id
  );

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setShowMessageActions(false);
    } else if (event.key === "Escape") {
      setShowMessageActions(false);
    }
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    //setshowArrow({ status: false, id: null });
    setAnchorEl(null);
  };

  useEffect(() => {
    document.addEventListener("click", handleClose);

    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, []);

  const handleMessageAction = (action: string, item: any) => {
    setShowMessageActions(false);
    if (action === "forward" || action === "delete") {
      handleMessageSelect(true, item);
      setChangeFooter(true);
      setShowCheckbox(true);
    } else {
      setChangeFooter(false);
      setShowCheckbox(false);
    }

    if (action === "heading") {
      setData(topicsData);
      setSelectedHeading(item?.message);
      setSelectedHeadingId(item?._id);
      setShowHeadingModal(true);
    }
    if (action === "favourite") {
      emit("addChatsToFavourite", {
        roomId: activeRoomData?._id,
        cid: [item?._id],
      });
    } else if (action === "unFavourite") {
      emit("removeChatsFromFavourite", {
        roomId: activeRoomData?._id,
        cid: [item?._id],
      });
    }

    if (action === "reply") {
      setTimeout(() => {
        document.getElementById('message-input').focus()
      }, 0)
      setReplyMessage(item);
      setReplyFooterShow(true);
    }

    if (action === "info" && item?.sender === userData?._id) {
      setMessageInfoItem(item);
      setMessageInfoDrawerShow(true);
    }
    if (action === "pin") {
      if (canYouPinMessage) {
        if (pinnedChats?.length < 3) {
          emit("pinChat", { roomId: activeRoomData?._id, cid: [item?._id] });

          socketListen("message", (data: any) => {
            if (data.type === "pinChat") {
              setToastShow(true);
              setToastMessage(t("chat_pinned"));
            }
          });
        } else {
          setToastMessage(t("chat_pin_validation"));
          setToastShow(true);
        }
      } else {
        setToastMessage(t("chat_pin_admin"));
        setToastShow(true);
      }
    }
  };

  const handleMessageSelect = (e: any, item: any) => {
    let temp = [...messages.msgId];
    let tempMsgId = [...messages.senderId];
    let tempTime = [...messages.time];

    if (e) {
      temp.push(item?._id);
      tempMsgId.push(item?.sender);
      tempTime.push(item?.created_at);
    } else {
      temp = temp.filter((elem: any) => elem !== item?._id);
      tempMsgId = tempMsgId.filter((elem: any) => elem !== item?.sender);
      tempTime = tempTime.filter((elem: any) => elem !== item?.created_at);
    }
    setMessages({ msgId: temp, senderId: tempMsgId, time: tempTime });
  };

  const handleDownloadFile = () => {
    const url = DefaultImageUrl + item?.fileURL;
    window.open(url);
    setShowMessageActions(false);
  };
  return item?.sender === userData?._id ? (
    <div
      className=" items-center"
      onMouseEnter={() => {
        setshowArrow({ status: true, id: item._id });
        setAnchorEl(null);
      }}
      onMouseLeave={() => {
        setshowArrow({ status: false, id: null });
        setAnchorEl(null);
      }}
    >
      <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />
      {showCheckbox && deleted && (
        <div className="h-[20px] w-[20px]">
          <input
            type="checkbox"
            name="message"
            className="mr-2 cursor-pointer h-[20px] w-[20px]"
            checked={messages?.msgId.includes(item._id)}
            onChange={(e: any) => handleMessageSelect(e.target.checked, item)}
          />
        </div>
      )}
      <div className="w-max ml-auto  max-w-[400px]">
        <div className="flex items-end">
          <div className="flex items-start">
            <div
              className="w-8 h-6"
              style={{ color: "#33CCFF", fontSize: "1.3rem" }}
            >
              {showArrow.status && showArrow.id === item?._id && deleted && (
                <div
                  className=""
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setActiveFav(item);
                  }}
                  ref={anchorRef}
                >
                  <MoreVertIcon
                    className="-pr-2 cursor-pointer"
                    sx={{ color: "#33CCFF", fontSize: "1.8rem" }}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  />
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => {
                      setAnchorEl(null);
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleDownloadFile}>
                      <div className="pr-2 mt-1">
                        <FileDownloadOutlinedIcon sx={{ color: "#33ccff" }} />
                      </div>
                      <div className="text-[14px]">{t("download")}</div>
                    </MenuItem>
                    {MessageAction.filter((elem: MessagesActionDataTypes) =>
                      activeRoomData?.type === "individual"
                        ? elem?.action !== "pin" && elem?.action !== "copy"
                        : elem?.action !== "copy"
                    ).map((el: MessagesActionDataTypes, index: number) => {
                      const pinchat = pinnedChats.filter(
                        (els: any) => els._id === item?._id
                      );
                      return pinchat.length > 0 && el?.action === "pin" ? (
                        ""
                      ) : activeFav?.favourite_by?.length > 0 &&
                        el?.action === "favourite" ? (
                        ""
                      ) : activeFav?.favourite_by?.length === 0 &&
                        el?.action === "unFavourite" ? (
                        ""
                      ) : (activeFav?.type === "PDF" ||
                          activeFav?.type === "TEXT" ||
                          activeFav?.type === "DOC" ||
                          activeFav?.type === "DOCX" ||
                          activeFav?.type === "ZIP" ||
                          activeFav?.type === "XLS" ||
                          activeFav?.type === "XLSX" ||
                          activeFav?.type === "PPT" ||
                          activeFav?.type === "PPTX" ||
                          activeFav?.type === "APPLICATION") &&
                        el?.action === "copy" ? (
                        ""
                      ) : activeRoomData?.type == "broadcast" &&
                        el?.action == "heading" ? (
                        ""
                      ) : isRoomLeft &&
                        (el?.action === "forward" ||
                          el?.action === "reply" ||
                          el?.action === "delete") ? (
                        ""
                      ) : (
                        <MenuItem
                          onClick={() => {
                            handleMessageAction(el.action, item);
                            setshowArrow({ status: false, id: null });
                            setAnchorEl(null);
                          }}
                          key={index}
                          sx={{ fontSize: "15px" }}
                        >
                          {el.ui}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </div>
              )}
            </div>

            <div
              onContextMenu={(e) => {
                if (!item?.message?.includes("You deleted this message")) {
                  e.preventDefault();
                  setAnchorEl(e.currentTarget);
                  setShowMessageActions(true);
                  setActiveFav(item);
                }
              }}
              className=" block gap-2 bg-[#E0FAFF] p-4 rounded-t-xl rounded-bl-xl px-3 py-2 group relative"
            >
              {item?.favourite_by?.length > 0 && (
                <div>
                  <StarIcon
                    sx={{ fontSize: 14 }}
                    className="text-black mt-1 me-1"
                  />
                </div>
              )}

              <div
                className={`flex mb-1 ${
                  item.isForwarded ? "justify-between" : "justify-end"
                }`}
              >
                {item?.isForwarded && (
                  <div className="flex">
                    <IoIosShareAlt className="mt-1 mx-1" />
                    <div className="text-sm textGray text-right">
                      {t("forwarded")}
                    </div>
                  </div>
                )}

                <div className="textGray text-[13px] pr-2">{messageTime}</div>
              </div>

              <div className="flex">
                <div
                  className={` flex ${
                    item.message !== "" &&
                    item?.message !== "You deleted this message" &&
                    item?.message !== "This message was deleted"
                      ? " rounded-md p-2 bg-[#b9ecf6]"
                      : ""
                  }`}
                >
                  {item?.message !== "You deleted this message" &&
                    item?.message !== "This message was deleted" && (
                      <div
                        ref={anchorRef}
                        className="h-[35px] min-w-[35px] w-[35px]"
                      >
                        {getFileExtension(item?.fileURL) == "pdf" && (
                          <img
                            src={PdfFileIcon}
                            alt="text"
                            className="w-[35px] h-[35px]"
                          />
                        )}

                        {getFileExtension(item?.fileURL) == "text" && (
                          <img
                            src={TextFileIcon}
                            alt="text"
                            className="w-[35px] h-[35px]"
                          />
                        )}

                        {(getFileExtension(item?.fileURL) == "doc" ||
                          getFileExtension(item?.fileURL) == "docx" ||
                          getFileExtension(item?.fileURL) == "wav") && (
                          <img
                            src={DocFileIcon}
                            alt="text"
                            className="w-[35px] h-[35px]"
                          />
                        )}

                        {getFileExtension(item?.fileURL) == "zip" && (
                          <img
                            src={ZipFileIcon}
                            alt="text"
                            className="w-[35px] h-[35px]"
                          />
                        )}

                        {(getFileExtension(item?.fileURL) == "xls" ||
                          getFileExtension(item?.fileURL) == "xlsx") && (
                          <img
                            src={XlsxFileIcon}
                            alt="text"
                            className="w-[35px] h-[35px]"
                          />
                        )}

                        {(getFileExtension(item?.fileURL) == "ppt" ||
                          getFileExtension(item?.fileURL) == "pptx") && (
                          <img
                            src={PptFileIcon}
                            alt="text"
                            className="w-[35px] h-[35px]"
                          />
                        )}

                        {(getFileExtension(item?.fileURL) == "jpg" ||
                          getFileExtension(item?.fileURL) == "jpeg" ||
                          getFileExtension(item?.fileURL) == "png") && (
                          <img
                            src={Media}
                            alt="text"
                            className="w-[35px] h-[35px]"
                          />
                        )}
                        {getFileExtension(item?.fileURL) == "APPLICATION" && (
                          <img
                            src={FileIcon}
                            alt="text"
                            className="w-[35px] h-[35px]"
                          />
                        )}
                      </div>
                    )}
                  {item?.message !== "You deleted this message" &&
                    item?.message !== "This message was deleted" && (
                      <>
                        {getFileExtension(item?.fileURL) == "mp3" ||
                        getFileExtension(item?.fileURL) == "wav" ||
                        getFileExtension(item?.fileURL) == "ogg" ? (
                          <div id="audio">
                            <audio
                              src={DefaultImageUrl + item?.fileURL}
                              controls
                              onLoadedMetadata={() => console.log()}
                            ></audio>
                          </div>
                        ) : (
                          <div
                            className={`text-sm ms-1 flex items-center ${
                              item?.message !== "You deleted this message"
                                ? ""
                                : "textGray"
                            }`}
                          >
                            {item?.fileURL.split("/").pop()}
                          </div>
                        )}
                      </>
                    )}
                </div>
              </div>
              <div
                className={` block justify-end bg-[#E0FAFF]  py-[6px rounded-t-xl rounded-bl-xl  mb-3`}
              >
                {item?.message !== "You deleted this message" &&
                item?.message !== "This message was deleted" ? (
                  <div className="text-[16px]   text-[#515151]">
                    {item?.message.replace(
                      "You deleted this message",
                      t("you-message-deleted")
                    )}
                  </div>
                ) : (
                  <div className="text-[14px] text-[#515151] mt-2 ">
                    <i>
                      {" "}
                      {item?.message.replace(
                        "You deleted this message",
                        t("you-message-deleted")
                      )}
                    </i>
                  </div>
                )}
              </div>
            </div>
          </div>

          {item.isSent && item.read_by.length > 0 && item.receipts ? (
            <></>
          ) : (
            <div className="flex items-end ">
              {item.isSent && item.delivered_to.length > 0 ? (
                <DoneAllIcon
                  className="text-gray-400"
                  sx={{ width: 18, height: 18 }}
                />
              ) : (
                <DoneIcon
                  className="text-gray-400"
                  sx={{ width: 18, height: 18 }}
                />
              )}
            </div>
          )}
        </div>
        {item.receipts &&
          activeRoomChats[activeRoomChats.length - 1]?._id === item._id &&
          item.isSent &&
          item.read_by.length > 0 && (
            <div
              className={` ml-auto  mb-2 ${
                activeRoomData?.type === "group" ? "w-12" : "w-fit ml-[5px]"
              } flex gap-1 items-end self-end justify-end`}
            >
              {<MessageInfo item={item} />}
            </div>
          )}
      </div>
    </div>
  ) : (
    <div
      className="flex w-full"
      onMouseEnter={() => {
        setshowArrow({ status: true, id: item._id });
        setAnchorEl(null);
      }}
      onMouseLeave={() => {
        setshowArrow({ status: false, id: null });
        setAnchorEl(null);
      }}
    >
      <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />
      {showCheckbox && deleted && (
        <div className="h-[20px] w-[20px] mr-4">
          <input
            type="checkbox"
            name="message"
            className="mr-2 cursor-pointer h-[20px] w-[20px]"
            checked={messages?.msgId.includes(item._id)}
            onChange={(e: any) => handleMessageSelect(e.target.checked, item)}
          />
        </div>
      )}
      <div>
        <div
          className={`flex pr-4 mr-auto max-w-[80%] w-fit`}
        >
          <div className="flex items-start">
            <div className="py-[6px] mx-2">
              <div className="flex gap-2   group relative">
                <div className="min-w-[35px]">
                  {showDate &&
                    (messageSender?.profile !== "" && !isUserBlocked ? (
                      <img
                        src={DefaultImageUrl + messageSender?.profile}
                        alt="asas"
                        className="h-[35px] w-[35px] rounded-full"
                      />
                    ) : (
                      <div className="">
                        <Avatar sx={{ width: "35px", height: "35px" }} />
                      </div>
                    ))}
                </div>
                <div
                  onContextMenu={(e) => {
                    if (!item?.message?.includes("This message was deleted")) {
                      e.preventDefault();
                      setAnchorEl(e.currentTarget);
                      setShowMessageActions(true);
                      setActiveFav(item);
                    }
                  }}
                  className="bg-[#F4F3F4] rounded-tr-xl rounded-b-xl px-3 py-2"
                >
                  {showDate && (
                    <div className="flex gap-3 justify-between">
                      <div className="flex mb-2 text-sm">
                        {showDate && messageSender?.name}
                        {/* {userDetails &&
                        (userDetails?.firstName !== "" ||
                          userDetails?.lastName !== "")
                          ? userDetails?.firstName + " " + userDetails?.lastName
                          : senderInfo?.phone} */}
                        {item?.isForwarded && (
                          <div className="flex">
                            <IoIosShareAlt className="mt-1 mx-1" />
                            <div className="text-sm textGray mb-1 ">
                              {t("forwarded")}
                            </div>
                          </div>
                        )}
                      </div>
                      {
                        <div className="textGray text-[12px] pr-2">
                          {messageTime}
                        </div>
                      }
                    </div>
                  )}

                  {item.message !== "This message was deleted" && (
                    <div
                      className="flex cursor-pointer mt-2"
                      onClick={() => {
                        window.open(DefaultImageUrl + item.fileURL, "_blank");
                      }}
                    >
                      <div
                        className={` flex ${
                          item.message !== "" &&
                          item?.message !== "You deleted this message"
                            ? " rounded-md p-2 bg-[#e0e0e0]"
                            : ""
                        }`}
                      >
                        <div
                          ref={anchorRef}
                          id="audio"
                          onClick={() => {
                            setShowMessageActions(true);
                            setActiveFav(item);
                          }}
                          className={
                            getFileExtension(item?.fileURL) == "mp3" ||
                            getFileExtension(item?.fileURL) == "wav"
                              ? "w-full "
                              : "h-[35px] min-w-[35px] w-[35px] "
                          }
                        >
                          {(getFileExtension(item?.fileURL) == "mp3" ||
                            getFileExtension(item?.fileURL) == "wav") && (
                            <div className="rounded-md p-2 py-3">
                              <audio
                                src={DefaultImageUrl + item?.fileURL}
                                controls
                              />
                            </div>
                          )}
                          {getFileExtension(item?.fileURL) == "pdf" && (
                            <img
                              src={PdfFileIcon}
                              alt="text"
                              className="w-[35px] h-[35px]"
                            />
                          )}

                          {getFileExtension(item?.fileURL) == "text" && (
                            <img
                              src={TextFileIcon}
                              alt="text"
                              className="w-[35px] h-[35px]"
                            />
                          )}

                          {(getFileExtension(item?.fileURL) == "doc" ||
                            getFileExtension(item?.fileURL) == "docx") && (
                            <img
                              src={DocFileIcon}
                              alt="text"
                              className="w-[35px] h-[35px]"
                            />
                          )}

                          {getFileExtension(item?.fileURL) == "zip" && (
                            <img
                              src={ZipFileIcon}
                              alt="text"
                              className="w-[35px] h-[35px]"
                            />
                          )}

                          {(getFileExtension(item?.fileURL) == "xls" ||
                            getFileExtension(item?.fileURL) == "xlsx") && (
                            <img
                              src={XlsxFileIcon}
                              alt="text"
                              className="w-[35px] h-[35px]"
                            />
                          )}

                          {(getFileExtension(item?.fileURL) == "ppt" ||
                            getFileExtension(item?.fileURL) == "pptx") && (
                            <img
                              src={PptFileIcon}
                              alt="text"
                              className="w-[35px] h-[35px]"
                            />
                          )}

                          {getFileExtension(item?.fileURL) == "APPLICATION" && (
                            <img
                              src={FileIcon}
                              alt="text"
                              className="w-[35px] h-[35px]"
                            />
                          )}
                        </div>
                        <div className="flex items-center">
                          {!(
                            getFileExtension(item?.fileURL) =="mp3" ||
                            getFileExtension(item?.fileURL) =="wav"
                          ) && (
                            <div
                              className={`text-sm ms-1 flex items-center ${
                                item?.message !== "You deleted this message"
                                  ? ""
                                  : "textGray"
                              }`}
                            >
                              {item?.fileURL.split("/").pop()}
                            </div>
                          )}

                          {item?.favourite_by?.length > 0 && (
                            <div className="flex justify-end">
                              <StarIcon
                                sx={{ fontSize: 14 }}
                                className="text-black mt-1 me-1"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {item?.message !== "" &&
                    (item.message === "This message was deleted" ? (
                      <div className=" text-[13px]  w-fit  rounded-tr-xl rounded-b-xl  ">
                        <i className="pr-2 text-[#515151]">
                          {item?.message.replace(
                            "This message was deleted",
                            t("this-message-deleted")
                          )}
                        </i>
                      </div>
                    ) : (
                      <div className=" text-[14px]  w-fit  rounded-tr-xl rounded-b-xl mt-2 text-[#515151] ">
                        {item?.message.replace(
                          "This message was deleted",
                          t("this-message-deleted")
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className="w-8 h-6"
              style={{ color: "#33CCFF", fontSize: "1.3rem" }}
            >
              {deleted && (
                <div
                  className=""
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setActiveFav(item);
                  }}
                  ref={anchorRef}
                >
                  <MoreVertIcon
                    className="-ml-2 cursor-pointer"
                    sx={{
                      visibility:
                        showArrow.status && showArrow.id === item?._id
                          ? "visible"
                          : "hidden",
                      color: "#33CCFF",
                      fontSize: "1.8rem",
                    }}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  />
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => {
                      setAnchorEl(null);
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleDownloadFile}>
                      <div className="pr-2 mt-1">
                        <FileDownloadOutlinedIcon sx={{ color: "#33ccff" }} />
                      </div>
                      <div className="text-[14px]">{t("download")}</div>
                    </MenuItem>
                    {MessageAction.filter((elem: MessagesActionDataTypes) =>
                      activeRoomData?.type === "individual"
                        ? elem?.action !== "pin" &&
                          elem.action !== "info" &&
                          elem?.action !== "copy"
                        : elem.action !== "info" && elem?.action !== "copy"
                    ).map((el: MessagesActionDataTypes, index: number) => {
                      const pinchat = pinnedChats.filter(
                        (els: any) => els._id === item?._id
                      );
                      return pinchat.length > 0 && el?.action === "pin" ? (
                        ""
                      ) : activeFav?.favourite_by?.length > 0 &&
                        el?.action === "favourite" ? (
                        ""
                      ) : activeFav?.favourite_by?.length === 0 &&
                        el?.action === "unFavourite" ? (
                        ""
                      ) : (activeFav?.type === "PDF" ||
                          activeFav?.type === "TEXT" ||
                          activeFav?.type === "DOC" ||
                          activeFav?.type === "DOCX" ||
                          activeFav?.type === "ZIP" ||
                          activeFav?.type === "XLS" ||
                          activeFav?.type === "XLSX" ||
                          activeFav?.type === "PPT" ||
                          activeFav?.type === "PPTX" ||
                          activeFav?.type === "APPLICATION") &&
                        el?.action === "copy" ? (
                        ""
                      ) : activeRoomData?.type == "broadcast" &&
                        el?.action == "heading" ? (
                        ""
                      ) : isRoomLeft &&
                        (el?.action === "forward" ||
                          el?.action === "reply" ||
                          el?.action === "delete") ? (
                        ""
                      ) : (
                        <MenuItem
                          onClick={() => handleMessageAction(el.action, item)}
                          key={index}
                          sx={{ fontSize: "14px" }}
                        >
                          {el.ui}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentFilesPreview;
